.brand-button {
  padding: 0 26px;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  color: #fff;
  text-decoration: none;
  background-color: #2c88c9;
  border: 2px solid #2c88c9;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  white-space: nowrap;
  /* margin: 5px; */
  letter-spacing: 0px !important;
  transition: all 0.2s ease-in-out;
}
.brand-button:hover {
  background-color: white;
  color: #2c88c9;
}

.brand-button-invert {
  padding: 0 26px;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  color: #2c88c9;
  text-decoration: none;
  background-color: white;
  border: 2px solid #2c88c9;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  /* margin: 5px; */
  border-radius: 3px;
  letter-spacing: 0px !important;
  transition: all 0.2s ease-in-out;
}

.brand-button-invert:hover {
  background-color: #2c88c9;
  color: white;
}
