@import url(https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600;700&family=Montserrat:ital,wght@0,400;0,600;0,700;1,400&display=swap);
.brand-button {
  padding: 0 26px;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  color: #fff;
  text-decoration: none;
  background-color: #2c88c9;
  border: 2px solid #2c88c9;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  white-space: nowrap;
  /* margin: 5px; */
  letter-spacing: 0px !important;
  transition: all 0.2s ease-in-out;
}
.brand-button:hover {
  background-color: white;
  color: #2c88c9;
}

.brand-button-invert {
  padding: 0 26px;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  color: #2c88c9;
  text-decoration: none;
  background-color: white;
  border: 2px solid #2c88c9;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  /* margin: 5px; */
  border-radius: 3px;
  letter-spacing: 0px !important;
  transition: all 0.2s ease-in-out;
}

.brand-button-invert:hover {
  background-color: #2c88c9;
  color: white;
}

.event-info-rsvp-top {
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 25px;
  padding: 3px 0px;
  color: #262626;
  margin-bottom: 5px;
}
.event-info-rsvp-remaining {
  border: none;
  padding-left: 0;
  margin-left: 0;
  padding-bottom: 2px;
  clear: both;
  padding: 0;
  display: flex;
  align-items: center;
  /* font-size: 13px; */
  opacity: 0.8;
  position: relative;
  line-height: 130%;
  color: #555555;
}

.event-info-rsvp-remaining em {
  opacity: 1;
  background-color: transparent !important;
  border: 1.5px solid #2189c9;
  color: #555 !important;
  padding: 5px 8px;
  border-radius: 10px;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 10px 0 0;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  font-style: normal;
}

.event-summary .rsvp-button {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.already-register span {
  opacity: 1;
  font-size: 13px;
  font-weight: 600;
}

.event-container {
  max-width: 1920px;
  padding-bottom: 10px;
}

.event-item {
  background-color: rgb(250, 250, 250);
  /* border-radius: 12px; */
  margin-top: 0;
  display: flex;
  flex-direction: row;
  /* padding: 20px; */
  /* border-radius: 8px; */
  margin: 10px 0;
  overflow: hidden;
  align-items: center;
  /* cursor: pointer; */
  min-height: 40px;
  box-sizing: border-box !important;
  outline: none !important;
  flex-basis: 100%;
}

.event-bg-overlay::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: rgba(20, 30, 40, 0.1);
}

.event-hrs-item-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 0px;
  flex-basis: 25%;
}

.event-item__left {
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 0px;
  flex-basis: 25%; */
  position: relative;
  flex-direction: row;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}

.event-item__left .date-start {
  position: absolute;
  z-index: 3;
  bottom: 0;
  right: 10px;
}
.event-item__left .date-month {
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.primary-color {
  color: #2189c9;
}

.color-white {
  color: white;
}

.event-item__left .date-day {
  font-size: 26px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
}

.event-item__right {
  display: flex;
  width: 100%;
}

@media (min-width: 768px) {
  .event-item__right {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.event-item__right-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-item__right-info .event-item__image {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-left: 24px;
  flex-shrink: 0;
}

.event-item__right-info .event-item__image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: solid 1px rgba(17, 17, 17, 0.1);
  display: block;
  border-radius: 8px;
}

.event-item__right-info .event-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;

  transition: 0.3s ease opacity;
}

.event-item__right-info-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
  align-items: flex-start;
  width: 100%;
  flex-shrink: 1;
  overflow: hidden;
}

.event-item__type {
  display: flex;
  margin-top: 0;
  flex-direction: column;
}

.event-item__text {
  font-size: 15px;
  color: #555555;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
}

.event-item__text:before {
  display: block;
  content: "";
  width: 2px;
  height: calc(100% - 9px);
  position: absolute;
  left: 0;
}

.event-item__title {
  /* color: rgb(29, 33, 41); */
  font-size: 25px;
  margin-top: 4px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.event_location_time {
  /* margin: 8px -4px 0; */
  max-width: 100%;
}

.event-buttons {
  text-align: center;
  padding: 4px 0;
}

@media (max-width: 768px) {
  .event-item {
    flex-direction: column;
  }
  .event-item__right-info-inner {
    margin-left: 0px;
  }

  .event-item__right {
    flex-wrap: wrap;
    display: flex;
    padding: 10px;
  }
  .event-item__right-info {
    flex-wrap: wrap;
  }

  .event-buttons {
    width: 100%;
    display: block;
    justify-content: space-between;
  }

  .event-buttons button {
    width: 100%;
  }

  .event-buttons span {
    display: none;
  }
  .already-register {
    margin-top: 15px;
  }
}

.event-dates {
  margin-bottom: 5px;
}

.event-item .event-info-rsvp-remaining {
  /* font-size: 15px; */
  color: #555555;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.spots_remain {
  margin-bottom: 5px;
  margin-top: 5px;
}

.MuiChip-label {
  font-family: "Hind", Helvetica, Arial, sans-serif !important;
  color: #555555;
}

@media (max-width: 768px) {
  .event-buttons button {
    margin: 0px !important;
  }
}

/* individlaEvent styles  */
.individual-logo img {
  width: 150px;
}

.individual-container {
  background-color: rgb(250, 250, 250);
}

.individual-inner {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
}

@media (max-width: 1112px) {
  .individual-inner .individual-event__info,
  .individual-inner .individual-contact {
    flex-basis: 100% !important;
  }
}

@media (max-width: 768px) {
  .individual-buttons button {
    width: 100%;
    margin-top: 15px;
    margin-right: 0px !important;
  }
  .individual-event__content,
  .individual-contact__content {
    padding: 10px !important;
  }

  .individual-icon {
    display: none !important;
  }
}

.individual-inner .individual-event__info {
  flex-basis: 60%;
}

.individual-inner .individual-contact {
  flex-basis: 40%;
}

.individual-event__content,
.individual-contact__content {
  padding: 3rem;
}

.brand-text-color {
  color: #555;
}

.individual-icon {
  display: inline-block;
  background: rgba(255, 255, 255, 0.3);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  border-radius: 50%;
  margin-right: 10px;
}

.individual-container p {
  margin: 0px !important;
  font-size: 20px;
}
.individual-container a {
  color: white;
  text-decoration: none;
}

.individual-container a:hover {
  text-decoration: underline;
}

.individual-flex {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}

.individual-title h2 {
  margin-bottom: 0px;
}

.individual-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* filters styl  */
.event-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.event-filters .MuiInputBase-root {
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  font-size: inherit !important;
  color: #555 !important;
}

@media (min-width: 768px) {
  .event-item__right .MuiChip-root {
    font-size: inherit !important;
  }
}

@media (max-width: 768px) {
  .event-item__right .MuiChip-root {
    font-size: 1rem !important;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Hind", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  display: block;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body > div {
  font-size: 18px;
  font-size: 1.8rem;
}

p {
  margin: 0 0 1.382em;
  padding: 0;
}

b,
strong {
  font-weight: 600;
}

blockquote,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 1em 0;
  padding: 0 1em;
}

/* ## Heading
--------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1.2;

  margin: 0 0 0.382em;
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1.3em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1.1em;
}

h6 {
  font-size: 1.05em;
}

@media (min-width: 767px) {
  body {
    font-size: 2rem !important;
  }
  body > div {
    font-size: 2rem !important;
  }
  h1 {
    font-size: 1.2em !important;
  }
  h2 {
    font-size: 1em !important;
  }
  h3 {
    font-size: 1.4em !important;
  }
  h4 {
    font-size: 1.3em !important;
  }
  h5 {
    font-size: 1.2em !important;
  }
  h6 {
    font-size: 1.1em !important;
  }
}

#root {
  /* overflow: hidden; */
  /* min-height: 100vh; */
}

