@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600;700&family=Montserrat:ital,wght@0,400;0,600;0,700;1,400&display=swap");

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-height: 100%;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Hind", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  display: block;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body > div {
  font-size: 18px;
  font-size: 1.8rem;
}

p {
  margin: 0 0 1.382em;
  padding: 0;
}

b,
strong {
  font-weight: 600;
}

blockquote,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 1em 0;
  padding: 0 1em;
}

/* ## Heading
--------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1.2;

  margin: 0 0 0.382em;
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1.3em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1.1em;
}

h6 {
  font-size: 1.05em;
}

@media (min-width: 767px) {
  body {
    font-size: 2rem !important;
  }
  body > div {
    font-size: 2rem !important;
  }
  h1 {
    font-size: 1.2em !important;
  }
  h2 {
    font-size: 1em !important;
  }
  h3 {
    font-size: 1.4em !important;
  }
  h4 {
    font-size: 1.3em !important;
  }
  h5 {
    font-size: 1.2em !important;
  }
  h6 {
    font-size: 1.1em !important;
  }
}

#root {
  /* overflow: hidden; */
  /* min-height: 100vh; */
}
